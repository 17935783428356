<template>
    <div>
        <div class="cards">
            <div class="card-header d-lext justify-content-xl-between">
                <div></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6 border-light border-right pr-3">
                        <div class="d-flex justify-content-between">
                            <p class="robo-18-500 mb-4">
                                Vai trò
                            </p>
                            <div>
                                <a href="javascript:;" @click="setModalRoles(true)" class="btn btn--blue">Thêm mới</a>
                            </div>
                        </div>
                        <div v-if="roles && roles.count && roles.data" class="row">
                            <a v-for="role in roles.data" @click="getPermissions(role)" :class="selected_role === role ? 'bg--primary text-white' : 'bg-light text--gray'" href="javascript:;" class="p-2 mb-1 robo-16-500 radius-10 col-12 icon-right role-item">
                                {{role.name}}
                            </a>
                        </div>
                    </div>
                    <div id="permission" class="col-lg-6 border-light border-right pr-3 mt-5 mt-lg-0">
                        <div class="d-flex justify-content-between">
                            <p class="robo-18-500 mb-4">
                                Quyền
                            </p>
                        </div>
                        <div v-if="selected_role && permissions && permissions.count && permissions.data" class="row pl-2">
                            <!-- <input type="text" class="form-control border mb-3" @input="filterPermission()" v-model="search" style="height: 40px;"> -->
                            <div v-if="filtered_permissions && filtered_permissions.count && filtered_permissions.data" v-for="permission in filtered_permissions.data" :key="permission.id" class="mb-3">
                                <label class="form-check">
                                    <input class="form-check-input" type="checkbox" :checked="isHaveThis(permission.id)" @click="chosePermission(permission)">
                                    <span class="form-check-label">{{permission.name}}</span>
                                </label>
                            </div>
                        </div>
                        <div v-if="!selected_role" class="row">
                            <p class="col-12 robo-16-500">Chọn vai trò</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal__add_role">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header bg--blue">
                        <h5 class="modal-title">Thêm vai trò</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="onSubmitRole">
                            <div class="mb-3">
                                <label class="form-label">Tên vai trò:</label>
                                <input type="text" v-model="form_role.name" class="form-control">
                            </div>
                            <div class="form-footer text-center">
                                <button type="submit" class="btn bg-pri bd-pri text-white" :disabled="loading || ! form_role.name">
                                    <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
                                    <span>Thêm mới</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

  name: 'WorkspaceRoles',

  data () {
    return {
      roles: null,
      role_permissions: null,
      permissions: null,
      workspace_id: null,
      loading: false,
      selected_role: null,
      form_role: {
        name: ''
      },
      filtered_permissions: null,
      search: ''
    }
  },
  mounted () {
    let self = this
    self.workspace_id = this.$clinicId
    if (!self.workspace_id) return
    self.getRoles()
    self.getAllPermissions()
  },
  methods: {
    // filterPermission () {
    //   let self = this
    //   if (!self.permissions || !self.permissions.data) return
    //   // let ps = self.permission
    //   if (self.search) {
    //     self.filtered_permissions.data = self.filtered_permissions.data.filter(p => p.name.includes(self.search))
    //   } else {
    //     self.filtered_permissions.data = self.permissions.data
    //   }
    //   console.log(self.filtered_permissions)
    // },
    async getAllPermissions () {
      let self = this
      let params = {
        limit: 100,
        page: 1
      }
      await self.$rf.getRequest('DoctorRequest').getAllPermissions(params).then((resp) => {
        self.permissions = resp.data
        self.filtered_permissions = resp.data
      }).catch((e) => {
        console.log(e)
      }).finally(() => {

      })
    },
    async getRoles () {
      let self = this
      self.loading = true
      let params = {
        limit: 100
      }
      await self.$rf.getRequest('DoctorRequest').getWorkspaceRoles(self.workspace_id, params).then((resp) => {
        self.roles = resp.data
        if (resp.data && resp.data.count) {
          if (!self.selected_role) {
            self.getPermissions(resp.data.data[0])
          } else {
            let curr_role = resp.data.data.findIndex(rl => rl.id === self.selected_role.id)
            if (curr_role > -1) self.getPermissions(resp.data.data[curr_role])
          }
        }
      }).catch((e) => {
        console.log(e)
      }).finally(() => {
        self.loading = false
      })
    },
    async getPermissions (role) {
      let self = this
      setTimeout(() => {
        self.selected_role = role
        self.role_permissions = role.permissions
      }, 100)
    },
    onSubmitRole () {
      let self = this
      if (!self.form_role.name) {
        self.$toast.open({
          message: 'Vui lòng nhập tên',
          type: 'warning'
        })
        return
      }
      if (self.form_role && !self.form_role.id) {
        self.onAddNewRole()
      }
    },
    async onAddNewRole () {
      let self = this
      let params = {
        name: self.form_role.name || ''
      }
      await self.$rf.getRequest('DoctorRequest').addNewRole(self.workspace_id, params).then((resp) => {
        self.getRoles()
        self.selected_role = resp.data
        self.setModalRoles(false)
      }).catch((e) => {
        console.log(e)
        self.$toast.open({
          message: 'Thêm mới thất bại!',
          type: 'warning'
        })
      }).finally(() => {
        self.loading = false
      })
    },
    isHaveThis (id) {
      let self = this
      if (self.role_permissions) {
        return !!self.role_permissions.find(permission => permission.id === id)
      }
    },
    chosePermission (permission) {
      let self = this
      if (!self.selected_role || !permission) return
      if (self.isHaveThis(permission.id)) {
        self.deleteRolePermission(permission.id)
      } else {
        self.addRolePermission(permission.id)
      }
    },
    async addRolePermission (id) {
      let self = this
      let params = {
        role_id: self.selected_role.id || '',
        permission_id: id
      }
      await self.$rf.getRequest('DoctorRequest').addRolePermission(self.workspace_id, params).then((resp) => {
        self.getRoles()
      }).catch((e) => {
        console.log(e)
        self.$toast.open({
          message: 'Thêm mới thất bại!',
          type: 'warning'
        })
      }).finally(() => {})
    },
    async deleteRolePermission (id) {
      let self = this
      let params = {
        role_id: self.selected_role.id || '',
        permission_id: id
      }
      await self.$rf.getRequest('DoctorRequest').deleteRolePermission(self.workspace_id, params).then((resp) => {
        self.getRoles()
      }).catch((e) => {
        console.log(e)
        self.$toast.open({
          message: 'Thêm mới thất bại!',
          type: 'warning'
        })
      }).finally(() => {})
    },
    setModalRoles (show) {
      window.$('#modal__add_role').modal(show ? 'show' : 'hide')
    },
    setModalPermission (show) {
      window.$('#modal__permission').modal(show ? 'show' : 'hide')
    }
  }
}
</script>
<style lang="css" scoped>
.border-light {
    border-color: #f4f6fa
}

.icon-right {
    background: url(../../../public/assets/images/icon/right.svg);
    background-position: 97% 50%;
    background-repeat: no-repeat;
}

.role-item:hover {
    color: black;
    text-decoration: none;
}

.bg--primary {
    background-color: #20419B;
}
</style>