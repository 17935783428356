<template>
  <div>
    <div class="row">
      <div class="col-12"></div>
      <div class="col-12">
        <table class="table table-vcenter table-mobile-md card-table table-scroll">
          <thead class="d-none d-md-table">
            <tr>
              <th>{{ $t('workspace_setting.lbl_hd_info') }}</th>
              <th class="text-sm-center">{{ $t('workspace_setting.lbl_hd_contact') }}</th>
              <th class="text-sm-center"></th>
            </tr>
          </thead>
          <tbody
            v-if="doctors && doctors.count"
            id="contacts"
            :class="doctors && doctors.count == 10 ? 'h-100' : ''"
          >
            <tr
              class="mt-2 table-tr"
              v-for="(doctor, index) in doctors.data"
              style="height: 70px;"
              :key="index"
            >
              <td :data-label="$t('workspace_setting.lbl_hd_info')">
                {{ doctor.doctor && doctor.doctor.user.a }}
                <div class="d-flex py-1 align-items-center">
                  <div style="min-width: 40px;">
                    <span
                      v-if="doctor && doctor.doctor && doctor.doctor.user && doctor.doctor.user.avatar"
                      class="avatar avatar-sm avatar-rounded"
                      :style="'background-image: url(' + getImageURL(doctor.doctor.user.avatar) + ')'"
                      style="height: 60px; width: 60px;"
                    ></span>
                    <span
                      v-if="doctor && doctor.doctor && doctor.doctor.user && !doctor.doctor.user.avatar"
                      class="avatar avatar-sm avatar-rounded bg-dark text-white"
                      style="height: 60px; width: 60px;"
                    >
                      {{
                      doctor && doctor.doctor && doctor.doctor.name ? doctor.doctor.name.charAt(0) : '' }}
                    </span>
                  </div>
                  <div class="flex-fill ml-1">
                    <p
                      class="p-0 m-0"
                    >{{ doctor && doctor.doctor && doctor.doctor.name ? doctor.doctor.name : '' }}</p>
                    <span class="robo-12-400 text-muted">
                      {{ doctor && doctor.doctor && doctor.doctor.id ? doctor.doctor.id
                      : '' }} - {{ doctor && doctor.doctor && doctor.doctor.email ? doctor.doctor.email : '' }}
                    </span>
                  </div>
                </div>
              </td>
              <td :data-label="$t('workspace_setting.lbl_hd_contact')" class="text-sm-center">
                <span class="robo-12-400 text-muted">
                  {{ doctor && doctor.doctor && doctor.doctor.phone ?
                  formatPhoneNumber(doctor.doctor.phone) : '' }}
                </span>
                <br />
                <span
                  class="robo-12-400 text-muted"
                >{{ doctor && doctor.addresss ? doctor.address : '' }}</span>
              </td>
              <td data-label class="text-sm-center">
                <div align="right" v-if="can('update-doctor-join-request') && !isOrgPage">
                  <button
                    class="btn bg--babyblue txt-pri"
                    @click="accpetPersonWorkspace(doctor && doctor.id)"
                  >
                    {{
                    $t('workspace_setting.btn_confirm') }}
                  </button>
                  <button
                    class="btn bg--red-corl text--red ml-1"
                    @click="rejectPersonWorkspace(doctor && doctor.id, doctor && doctor.doctor && doctor.doctor.id)"
                  >
                    {{
                    $t('workspace_setting.btn_cancel') }}
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3" v-if="doctors && !doctors.data">
                <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
                  <img
                    src="../../../public/assets/images/not-found-search.png"
                    class="img-responsive p-0"
                    alt
                  />
                  <p
                    class="robo-16-400 text-black"
                  >{{ $t(search ? 'lbl_no_records_match_your_search' : 'lbl_you_have_not_had_any_patient_yet' )}}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <Pagination
          v-if="doctors && doctors.count"
          :items="doctors"
          :current_page="current_page"
          @onRefresh="getDoctorWsp"
        ></Pagination>-->
        <AppPagination
          :paginationProps="{ currentPage: current_page, totalItems, pageSize: limit }"
          @onChangePagination="(data) => getDoctorWsp(data)"
        />

        <div v-if="doctors && !doctors.count">
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden">
            <img
              src="../../../public/assets/images/not-found-search.png"
              class="img-responsive p-0"
              alt
            />
            <p class="robo-16-400 text-black">
              {{ $t(search ? 'lbl_no_records_match_your_search'
              : 'lbl_no_staff_has_joined') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import Pagination from '../../components/Pagination'
import { AppPagination } from '../Common'

export default {
  name: 'DoctorWorkspaceClinic',
  components: { Pagination, AppPagination },
  props: {
    wsId: Number,
    isOrgPage: Boolean
  },
  data () {
    return {
      doctors: [],
      roles: [],
      loading: true,
      wsp_id: this.wsId || this.$clinicId || null,
      my_dr_id: null,
      current_page: 1,
      search: '',
      limit: 15,
      totalItems: 0
    }
  },
  mounted () {
    let self = this
    self.wsp_id = this.wsId || this.$clinicId
    let user = appUtils.getLocalUser()
    self.my_dr_id = user && user.doctor && user.doctor.id
    self.getDoctorWsp()
  },
  methods: {
    async getDoctorWsp (data) {
      let self = this
      self.loading = true
      let params = {
        limit: data?.pageSize || self.limit,
        page: data?.currentPage || self.current_page,
        order: 'created_at',
        order_by: 'desc',
        clinic_id: self.wsp_id
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getDoctorWorkspaceClinic(params)
        .then(resp => {
          self.doctors = resp.data
          self.totalItems = resp.data?.total || 0
          self.limit = Number(resp.data?.per_page) || 0
          self.current_page = resp.data?.current_page || 1
          self.$emit('waitingCount', self.doctors.total)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          self.loading = false
        })
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    formatPhoneNumber (number) {
      // Filter only numbers from the input
      let cleaned = ('' + number).replace(/\D/g, '')
      // Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      return match ? match[1] + '-' + match[2] + '-' + match[3] : ''
    },
    async accpetPersonWorkspace (id) {
      let self = this
      if (!self.can('update-doctor-join-request')) return
      try {
        var r = confirm(this.$t('workspace_setting.lbl_add2ws_confirm'))
        if (r) {
          if (id) {
            var clinic_id = this.$clinicId
            var params = {
              clinic_id: clinic_id
            }
            await self.$rf
              .getRequest('DoctorRequest')
              .acceptDoctorTicketsByID(id, params)
              .then(res => {
                self.getDoctorWsp()
              })
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async rejectPersonWorkspace (id, doctor_id) {
      let self = this
      if (!self.can('update-doctor-join-request')) return
      try {
        var r = confirm(this.$t('workspace_setting.lbl_remove2ws_confirm'))
        if (r) {
          if (id) {
            var clinic_id = this.$clinicId
            var params = {
              clinic_id: clinic_id
            }
            await self.$rf
              .getRequest('DoctorRequest')
              .rejectDoctorTicketsByID(id, params)
              .then(res => {
                self.getDoctorWsp()
              })
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped></style>
