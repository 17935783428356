<template>
    <div class="col-12 mb-3">
        <div align="right" class="cursor-pointer robo-16-500 txt-pri" @click="gotoHistory()"><img src="../../../public/assets/images/icon/rotate-left.svg"> Lịch sử</div>
        <div class="row mb-3" v-if="!!disease">
            <div class="col-xl-4 col-lg-6" v-if="current_incharge">
                <div class="robo-16-500 text-gray mb-2">Bác sĩ điều trị</div>
                <div class="relative">
                    <div v-if="current_incharge" class="bg-blue-gray d-flex justify-content-between cursor-pointer p-2 align-items-center">
                        <div class="d-flex align-items-center justify-content-start" :set="doctor = current_incharge ? current_incharge : []">
                            <span v-if="doctor && doctor.user" class="mr-3">
                                <span class="avatar avatar-sm avatar-rounded" v-if="doctor.user.avatar" :style="'background-image: url('+getImageURL(doctor.user.avatar)+')'"></span>
                                <span v-if="!doctor.user.avatar" class="avatar avatar-sm avatar-rounded bg-dark text-white">
                                    <span style="color: #fff;">{{ doctor.name ? doctor.name.charAt(0) : '' }}</span>
                                </span>
                            </span>
                            <p class="robo-16-500 txt-pri mb-0">{{doctor.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6" v-if="current_supporters && current_supporters.length > 0">
                <div class="robo-16-500 text-gray mb-2">Người hỗ trợ</div>
                <div class="relative">
                    <div class="bg-blue-gray d-flex justify-content-between cursor-pointer p-2 align-items-center">
                        <div class="d-flex align-items-center justify-content-start">
                            <span v-if="doctors" class="mr-3" v-for="(doctor, index) in current_supporters" :key="index">
                                <span class="avatar avatar-sm avatar-rounded" v-if="doctor.user.avatar" :style="'background-image: url('+getImageURL(doctor.user.avatar)+')'" :title="doctor.name"></span>
                                <span v-if="!doctor.user.avatar" class="avatar avatar-sm avatar-rounded bg-dark text-white">
                                    <span style="color: #fff;" :title="doctor.name">{{ doctor.name ? doctor.name.charAt(0) : '' }}</span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <label for="" class="robo-20-500 txt-pri">Chẩn đoán</label>
            </div>
            <div class="col-12 mb-3">
                <label for="" class="robo-16-500 txt-muted mb-2">Tên bệnh</label>
                <input :readonly="true" type="text" class="form-control bg-blue-gray" v-model="form.diseases_name" />
            </div>
            <div class="col-12 mb-3">
                <label for="" class="robo-16-500 txt-muted mb-2">Diễn biến bệnh</label>
                <textarea
                  :readonly="true"
                  class="form-control bg-blue-gray"
                  placeholder="Diễn biến bệnh như thế nào?"
                  v-model="form.diseases_stage"
                  style="height: 100px;"
                ></textarea>
            </div>
            <div class="col-12 mb-3">
                <label for="" class="robo-20-500 txt-pri mb-2">Diễn biến sức khỏe</label>
                <textarea :readonly="true" class="form-control bg-blue-gray" v-model="form.diseases_current_cure" style="height: 200px;"></textarea>
            </div>
            <div class="col-12 mb-3">
                <label for="" class="robo-20-500 txt-pri mb-2">Đã điều trị</label>
                <textarea :readonly="true" class="form-control bg-blue-gray" v-model="form.diseases_previous_cure" style="height: 200px;"></textarea>
            </div>
            <div class="col-12 mb-3">
                <label for="" class="robo-20-500 txt-pri mb-2">Bệnh khác</label>
                <textarea :readonly="true" class="form-control bg-blue-gray" v-model="form.background_diseases" style="height: 200px;"></textarea>
            </div>
        </div>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'

export default {
  props: ['disease', 'person', 'type'],
  name: 'CMSInfomationForm',

  data () {
    return {
      loading: false,
      doctors: [],
      current_incharge: null,
      current_supporters: [],
      owner_clinic_sp: [],
      care_team: null,
      form: {
        person_id: '',
        person_diseases_id: '',
        diseases_name: '',
        diseases_stage: '',
        diseases_current_cure: '',
        diseases_previous_cure: '',
        background_diseases: '',
        treatment_summary: ''
      }

    }
  },
  watch: {
    disease: function (new_value, old_value) {
      let self = this
      self.form = {
        person_diseases_id: new_value.person_diseases_id || '',
        diseases_name: new_value.diseases_name || '',
        diseases_stage: new_value.diseases_stage || '',
        diseases_previous_cure: new_value.diseases_previous_cure || '',
        diseases_current_cure: new_value.diseases_current_cure || '',
        background_diseases: new_value.background_diseases || '',
        treatment_summary: new_value.treatment_summary || ''
      }
      let care_team = new_value.care_team
      if (care_team && care_team.care_team_doctor && care_team.care_team_doctor.length) {
        let care_team_doctor = care_team.care_team_doctor
        let incharge = null
        let supporters = []
        let owner_clinic_sp = []
        care_team_doctor.forEach(dr => {
          if (parseInt(dr.role) === 1) {
            incharge = dr.doctor
          }
          if (parseInt(dr.role) === 2) {
            supporters.push(dr.doctor)
          }
          if (parseInt(dr.role) === 3) {
            owner_clinic_sp.push(dr.doctor)
          }
        })
        self.current_incharge = incharge
        self.current_supporters = supporters
        self.owner_clinic_sp = owner_clinic_sp
      }
    },
    person: function (new_value, old_value) {
      let self = this
      self.form = {
        person_id: new_value.id || ''
      }
    }
  },
  mounted () {
  },
  methods: {
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    gotoHistory () {
      this.$router.push({ path: `/admin/diseases/${this.$route?.params?.id}/profile-history` })
    }
  }
}
</script>
<style lang="css" scoped>
.relative {
    position: relative;
    z-index: 9;
}

.form-control {
    color: #242424;
    font-family: 'roboto';
    font-size: 16px;
    font-weight: 400
}
</style>