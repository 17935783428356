<template>
  <div
    class="modal fade"
    id="modal__sign"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="robo-20-500 mb-0">{{ $t("workspace_setting.invite_staff")[$orgModel] }}</p>
          <img
            src="../../../public/assets/images/icon/x-gray.svg"
            alt
            class="cursor-pointer"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="col-lg-12">
          <div class="mb-3">
            <div class="row">
              <div class="col-sm-6">
                <label class="robo-16-500 text-black mb-2 required">{{ $t('lbl_full_name') }}</label>
              </div>
              <div class="col-sm-6 text-right">
                <span class="robo-14-400 text--red">{{ error.name }}</span>
              </div>
            </div>
            <div
              class="input-group-login input-group"
              :class="error.name ? 'error-form-input-group' : ''"
            >
              <input
                @click="error.name = ''"
                @input="error.name = ''"
                v-model="form_doctor_sign_up.name"
                type="text"
                class="form-control form-input-login bg-white"
                :placeholder="$t('lbl_enter_full_name')"
              />
              <span class="input-group-text bg-white border-0">
                <i v-show="error.name" class="fa fa-exclamation-circle font-size-20 mr-2 text--red"></i>
              </span>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-sm-6">
                <label class="robo-16-500 text-black mb-2 required">{{ $t('lbl_job_title') }}</label>
              </div>
              <div class="col-sm-6 text-right">
                <span class="robo-14-400 text--red">{{ error.type }}</span>
              </div>
            </div>
            <select
              v-model="form_doctor_sign_up.type"
              class="form-control form-input bg-white fs-16 fw-500 form-select"
            >
              <option :value="0">{{ $t('lbl_doctor') }}</option>
              <option :value="1">{{ $t('lbl_healthcare_professional') }}</option>
              <option :value="2">{{ $t('lbl_specialist_doctor') }}</option>
              <option :value="3">{{ $t('lbl_nurse') }}</option>
              <option :value="4">{{ $t('lbl_other') }}</option>
            </select>
          </div>

          <div class="mb-3">
            <div class="row">
              <div class="col-sm-6">
                <label class="robo-16-500 text-black mb-2 required">Email</label>
              </div>
              <div class="col-sm-6 text-right">
                <span class="robo-14-400 text--red">{{ error.email }}</span>
              </div>
            </div>
            <div
              class="input-group-login input-group"
              :class="error.email ? 'error-form-input-group' : ''"
            >
              <input
                @click="error.email = ''"
                @input="error.email = ''"
                v-model="form_doctor_sign_up.email"
                type="email"
                class="form-control form-input-login bg-white"
                :placeholder="$t('lbl_enter_email')"
              />
              <span class="input-group-text bg-white border-0">
                <i
                  v-show="error.email"
                  class="fa fa-exclamation-circle font-size-20 mr-2 text--red"
                ></i>
              </span>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-sm-6">
                <label class="robo-16-500 text-black mb-2">{{ $t('lbl_phone_number') }}</label>
              </div>
              <div class="col-sm-6 text-right">
                <span class="robo-14-400 text--red">
                  {{
                  error.phone_number
                  }}
                </span>
              </div>
            </div>
            <div
              class="input-group-login input-group"
              :class="error.phone_number ? 'error-form-input-group' : ''"
            >
              <input
                @click="error.phone_number = ''"
                @input="error.phone_number = ''"
                v-model="form_doctor_sign_up.phone_number"
                type="text"
                class="form-control form-input-login bg-white"
                :placeholder="$t('lbl_enter_phone_number')"
              />
              <span class="input-group-text bg-white border-0">
                <i
                  v-show="error.phone_number"
                  class="fa fa-exclamation-circle font-size-20 mr-2 text--red"
                ></i>
              </span>
            </div>
          </div>

          <div class="mb-3 text-right">
            <button
              type="button"
              @click="onSignUp()"
              class="btn bg-pri bd-pri text-white"
            >{{ $t('lbl_send') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignUpPage',
  props: {
    wsId: Number,
    wsInfo: Object,
    isOrgPage: Boolean
  },
  computed: {},
  data () {
    return {
      form_doctor_sign_up: {
        email: '',
        name: '',
        phone_number: '',
        type: null
      },
      error: {
        email: '',
        name: '',
        phone_number: ''
      },
      loading: false,
      is_doctor: true
    }
  },
  mounted () {},
  methods: {
    async onSignUp () {
      const self = this
      const clinic_id = this.$clinicId || this.wsId
      if (!clinic_id) return
      let doctor = {}
      if (!self.form_doctor_sign_up.name) {
        self.error.name = this.$t('msg_please_enter_full_name')
        return false
      } else {
        doctor.name = self.form_doctor_sign_up.name
      }
      if (!self.form_doctor_sign_up.email) {
        self.error.email = this.$t('msg_please_enter_email')
        return false
      } else {
        doctor.email = self.form_doctor_sign_up.email
      }
      if (self.form_doctor_sign_up.phone_number) {
        doctor.phone_number = self.form_doctor_sign_up.phone_number
      }
      if (self.form_doctor_sign_up.type) {
        doctor.type = self.form_doctor_sign_up.type
      }

      let params = self.form_doctor_sign_up
      params.clinic_id = clinic_id
      try {
        self.loading = true
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .inviteDoctorWs(params)
        if (resp) {
          this.$toast.open({
            message: this.$t('msg_invitation_sent_successfully'),
            type: 'success'
          })
          window.$('#modal__sign').modal('hide')
        }
      } finally {
        self.$emit('refresh')
      }
    },
    checkPass (str) {
      if (str.length >= 6) {
        var result = str.match(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?_-]{6,12}$/
        )
          ? str
            .match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?_-]{6,12}$/)
            .toString()
          : ''
        if (result === str) {
          return true
        }
        return false
      }
      return false
    }
  }
}
</script>
<style scoped>
.error-form-input-group {
  border-color: #ffb8c4;
  background-color: #ffb8c4 !important;
  color: #de3854;
}

.error-form-input-group .form-control,
.error-form-input-group .input-group-text {
  background-color: #ffb8c4 !important;
  color: #de3854 !important;
}

.show-pw-form-input {
  background-color: #7386c7 !important;
  color: #41464b;
  border-color: #7386c7;
}

.show-pw-form-input .form-control,
.show-pw-form-input .input-group-text {
  background-color: #7386c7 !important;
  color: #41464b !important;
}

.error-form-input-group .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #de3854;
  opacity: 1;
  /* Firefox */
}

.error-form-input-group .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #de3854;
}

.error-form-input-group .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #de3854;
}

.decoration-under {
  text-decoration: underline;
}
</style>
