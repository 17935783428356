<template>
  <div class="mt-2">
    <div class="bg-white p-3 h-100" id="action-list">
      <div class="row">
        <div class="col-sm-6 offset-sm-2">
          <div class="text-center txt-pri robo-20-500">{{ $t("workspace_setting.lbl_default_workspace_setting")[$orgModel] }}</div>
          <div class="mt-2">
            <div v-if="workspaces && workspaces.length">
              <label class="mt-2 w-100 cursor-pointer" v-for="(workspace, index) in workspaces" :key="index" @click="setClinicDf(workspace)">
                <input class="d-none" type="radio" name="exampleRadios" :value="workspace.id" v-model="df_id" />
                <span class="request-person-info d-flex p-2 w-100">
                  <div class="person-avatar">
                    <span v-if="hasAvatar(workspace)" class="avatar avatar-md avatar-rounded" :style="'background-image: url(' + getImageURL(workspace.avatar) + ')'"></span>
                    <span v-if="!hasAvatar(workspace)" class="avatar avatar-md avatar-rounded text-white bg-dark">
                      {{ workspace && workspace.name ? workspace.name.charAt(0) : "" }}
                    </span>
                  </div>
                  <div class="ml-3">
                    <p class="m-0 robo-16-500">{{ workspace && workspace.name ? workspace.name : "" }}</p>
                    <div class="robo-14-400 txt-pri">{{ workspace && workspace.address ? workspace.address : "" }}</div>
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'clinicIsDefault',
  data () {
    return {
      type: '',
      workspaces: null,
      df_id: null
    }
  },
  mounted () {
    let self = this
    self.getClinics()
    self.getClinicDf()
  },
  methods: {
    async getClinics () {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .getClinic()
        .then(resp => {
          if (resp.data?.length) {
            self.workspaces = resp.data
          } else {
            self.$toast.open({
              message: this.$t('blank_state.lbl_wel_msg')[this.$orgModel],
              type: 'success'
            })
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {})
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    hasAvatar (wsp) {
      return wsp && wsp.avatar
    },
    async getClinicDf () {
      let self = this
      let params = {
        is_default: 1
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getWorkSpacesAll(params)
        .then(resp => {
          if (resp.data && resp.data.count) {
            let result = resp.data.data.find(item => item.is_default === 1)
            if (result) {
              self.df_id = result.clinic_id
            }
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {})
    },
    async setClinicDf (clinic) {
      let self = this
      let params = {
        is_default: 1
      }
      await self.$rf.getRequest('DoctorRequest').setDefaultWorkspace(clinic.id, params)
    }
  }
}
</script>
<style scoped>
.font-size-14 {
  font-size: 14px !important;
}
.bound-border {
  background-color: #ffffff;
  border-radius: 5px;
}
.p-10-10 {
  padding: 10px 10px;
}
:checked + span {
  background: #eaf2fb;
  border-radius: 8px;
  position: relative;
}
:checked + span:after {
  content: "";
  background-image: url("../../../public/assets/images/icon/icon-tick-square.svg");
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 15px;
  position: absolute;
  right: 15px;
}
</style>
