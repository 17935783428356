<template>
  <div class="row">
    <div class="telemedicine-management">
      <!-- <div class="row pl-3">
        <div class="col-6">
        </div>
        <div class="col-6 mb-1">
          <div class="d-flex justify-content-end">
            <button
              class="input-group-text btn btn-l btn-primary"
              @click="showAdd(true)"
            >Thêm mới +</button>
          </div>
        </div>
      </div>-->
      <div class="card radius-10 border-0 shadow-none">
        <!-- <div class="d-flex justify-content-end mb-3">
          <select
            name=""
            id=""
            v-model="sort"
            class="p-1 mr-1 form-select px-3 border mx-3"
            @change="getDiseasesTemplates()"
          >
            <option value="updated_at">Ngày cập nhật</option>
            <option value="created_at">Ngày tạo</option>
          </select>
          <select
            name=""
            id=""
            v-model="sort_by"
            class="p-1 form-select px-3 border mx-3"
            @change="getDiseasesTemplates()"
          >
            <option value="desc">Mới nhất trước</option>
            <option value="asc">Cũ nhất trước</option>
          </select>
          <div class="input-group">
            <input
              class="form-control h-100 border"
              type="text"
              v-model="search"
              @keyup.enter="onSearch()"
              @keyup.delete="onSearchDelete()"
            >
            <button
              class="input-group-text btn btn-light"
              @click="onSearch()"
            >Tìm kiếm</button>
          </div>
        </div>-->
        <div class="mt-4 mr-4 d-flex justify-content-between">
          <h3 class="font-weight-bold">{{ $t('lbl_personal_health_record_template') }}</h3>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="goToList"
            class="cursor-pointer"
          >
            <path
              d="M6 18.4998L17.9998 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 18.4998L6 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="temps && temps.data && !loading">
            <tr v-for="temp in temps.data" :key="temp.id">
              <td data-title="Tên hồ sơ">
                <span class="robo-16-400">{{temp.diseases_name}}</span>
              </td>
              <td class="d-flex justify-content-end">
                <div class="d-flex w-170px">
                  <button
                    class="btn bg-pri bd-pri text-white mt-1 mr-2"
                    @click="goToDetail(temp)"
                  >{{ $t('lbl_edit') }}</button>
                  <button
                    class="btn mt-1"
                    v-if="clinic_id == temp.clinic_id"
                    :class="!temp.status ? 'bg-pri bd-pri text-white' : 'btn-white'"
                    @click="deleteDiseasesTemplates(temp.id)"
                  >{{ $t('lbl_btn_delete') }}</button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="loading" class="h-100" style="min-height: 600px;">
            <tr>
              <td colspan="6" class="text-center py-5">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <AppPagination :paginationProps="pagination" @onChangePagination="getDiseasesTemplates" />
        <!-- <Pagination
          class="Content"
          v-show="temps && temps.count"
          v-if="temps"
          :items="temps"
          :current_page="current_page"
          @onRefresh="getDiseasesTemplates"
        ></Pagination>-->
      </div>
    </div>
  </div>
</template>

<script>
import { AppPagination } from '@/components/Common'

export default {
  name: 'TemplateManagement',
  components: { AppPagination },
  data () {
    return {
      temps: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      current_page: 1,
      clinic_id: -1,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },
  mounted () {
    let self = this
    self.getDiseasesTemplates()
    self.clinic_id = this.$clinicId
  },
  methods: {
    goToDetail (temp) {
      this.$router.push({
        path: `/doctor/diseases-templates/${temp.id}/clone`
      })
    },
    async getDiseasesTemplates (dataPage) {
      let self = this
      self.loading = true
      let clinic_id = this.$clinicId
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          status: 1,
          limit: dataPage?.pageSize || this.pagination.pageSize,
          page: dataPage?.currentPage || this.pagination.currentPage
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getTemplates(clinic_id, params)
          .then(res => {
            if (res && res.data) {
              self.temps = res.data

              this.pagination = {
                currentPage:
                  res.data?.current_page || this.pagination.currentPage,
                pageSize: res.data?.count || this.pagination.pageSize,
                totalItems: res.data?.total || 0
              }
            }
          })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async onCreate (data) {
      let self = this
      if (data) {
        self.$rf
          .getRequest('DoctorRequest')
          .createDiseasesTemplates(data)
          .then(resp => {
            self.$router.push(`/admin/diseases-templates/${resp.data.id}/show`)
          })
      }
    },
    async deleteDiseasesTemplates (id) {
      let self = this
      if (id) {
        self.$rf
          .getRequest('DoctorRequest')
          .deleteDiseasesTemplates(id)
          .then(resp => {
            self.getDiseasesTemplates()
          })
      }
    },
    onSearch () {
      let self = this
      self.getDiseasesTemplates()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getDiseasesTemplates()
      }
    },
    formatDDMMYYYY (date) {
      return window.moment(date).format('HH:mm DD/MM/YY')
    },
    showAdd (show) {
      this.$router.push({ path: `/admin/diseases-templates-create` })
    },
    goToList () {
      this.$router.push({ path: '/ws-mgmt' })
    }
  }
}
</script>

<style scoped>
.w-170px {
  width: 180px;
}
</style>
