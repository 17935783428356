<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow
      @onClose="onClose"
      :title="test_item && test_item.test_result_name"
      :show_calll="true"
    />
    <div class="table-responsive">
      <table class="table table-vcenter table-mobile-md card-table table-scroll">
        <thead>
          <tr>
            <th><span
                class="ml-sm-2 robo-16-500"
                style="color: #03267A;"
              >Xét nghiệm</span></th>
            <th><span
                class="robo-16-500"
                style="color: #03267A;"
              >Kết luận</span></th>
            <th><span
                class="robo-16-500"
                style="color: #03267A;"
              >Mô tả</span></th>
            <th><span
                class="robo-16-500"
                style="color: #03267A;"
              >Ngày chẩn đoán</span></th>
          </tr>
        </thead>
        <tbody v-if="person_tests && person_tests.data">
          <tr
            class="mt-2 mb-2 border-bottom"
            v-for="(person_test, index) in person_tests.data"
            :key="index"
          >
            <td data-label="Xét nghiệm"><span class="robo-16-500 txt-black-gray ml-sm-2">{{person_test && person_test.exploration_name}}</span></td>
            <td data-label="Kết luận">
              <pre
                class="border-0 robo-16-500 txt-black-gray ff-robo"
                style="background-color: transparent; padding: 0;"
              >{{person_test && person_test.exploration_result}}</pre>
            </td>
            <td data-label="Mô tả">
              <pre
                class="border-0 robo-16-500 txt-black-gray ff-robo"
                style="background-color: transparent; padding: 0;"
              >{{person_test && person_test.exploration_desc}}</pre>
            </td>
            <td
              data-label="Ngày chẩn đoán"
              class="robo-16-500"
            >
              <pre
                class="border-0 robo-16-500 txt-black-gray ff-robo"
                style="background-color: transparent; padding: 0;"
              >{{person_test && person_test.exploration_date ? formatDateDMY(person_test.exploration_date) : ''}}</pre>
            </td>
          </tr>
          <tr v-if="!person_tests || !person_tests.count">
            <td colspan="4">
              <div class="col-sm-12 robo-14-400 text-center">Không có dữ liệu</div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        v-show="person_tests && person_tests.data"
        :items="person_tests"
        :current_page="current_page"
        @onRefresh="getPersonDiseasesExploration"
      ></Pagination>
    </div>
  </div>
</template>
<script>
import PersonProfile from '../Diseases/PersonProfile.vue'
import HeaderWindow from '../Diseases/HeaderWindow.vue'
import TypeBar from '../Diseases/TypeBar.vue'
import Pagination from '../Pagination.vue'
export default {

  name: 'CmsListResults',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination },
  data () {
    return {
      title: 'Chẩn đoán',
      person_diseases_id: '',
      current_page: 1,
      person_tests: null,
      test_item: null
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.getPersonDiseasesExploration()
    self.getPersonTestResultByID()
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/admin/diseases/${this.$route?.params?.id}/detail` })
    },
    async getPersonDiseasesExploration () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          person_test_result_id: self.$route.query.test,
          sort: 'created_at',
          sort_by: 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getPersonDiseasesExploration(self.$route?.params?.id, params).then(res => {
          if (res && res.data) {
            self.person_tests = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    async getPersonTestResultByID () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonTestResultByID(self.$route.query.test).then(res => {
          if (res && res.data) {
            self.test_item = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
</style>