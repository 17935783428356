<template>
  <div class="bg-white rounded-2xl container p-4 mt-3">
    <div class="text-right pb-4">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="backWS"
        class="cursor-pointer"
      >
        <path
          d="M6 18.4998L17.9998 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 18.4998L6 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <div>
          <div align="center" class="d-flex align-items-center justify-content-center">
            <div class="border-avatar">
              <div
                class="avatar-no-clinic"
                v-if="!params.avatar && !url_avatar"
              >{{ params ? getCharAtFirst(params.name) : "" }}</div>
              <div
                class="avatar-clinic"
                v-if="!params.avatar && url_avatar"
                :style="`backgroundImage: url('${url_avatar}');`"
              ></div>
              <div
                class="avatar-clinic"
                v-if="params.avatar"
                :style="
                  url_avatar
                    ? `backgroundImage: url('${url_avatar}');`
                    : `backgroundImage: url('${getImageURL(params.avatar)}');`
                "
              ></div>
              <label class="cursor-pointer" v-if="can('update-facility')">
                <div class="add-avatar pt-2">
                  <div
                    class="bg-primary text-white cursor-pointer flex items-center justify-center rounded-lg h-10 w-24 px-3 py-2 focus:outline-none"
                  >
                    <div>{{ $t("workspace_setting.btn_logo_change") }}</div>
                  </div>
                  <input type="file" class="d-none" accept="image/*" @change="changeAvatar($event)" />
                </div>
              </label>
            </div>
          </div>
          <div align="center" class="mt-20">
            <qrcode
              v-if="qr_code"
              :value="qr_code"
              :options="{ width: 195 }"
              style="margin: 0 auto; padding: 6px"
              class
              :text="qr_code"
            ></qrcode>
            <div class="robo-18-500">
              <div class="robo-16-400">{{ $t("workspace_setting.lbl_workspace_code")[$orgModel] }}</div>
              <span id="copy-bank-number">{{ code }}</span>
              <span
                href="javascript:void(0)"
                @click="copyText('copy-bank-number')"
                title="Copy"
                style="cursor: pointer"
              >
                <img class="w-18h-20" src="../../../public/assets/images/icon/icon_copy_code.svg" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <form>
          <div class="row mb-3">
            <div class="form-group col-6 mb-3">
              <label class="robo-16-500 mb-2 required" for="clinic-name">
                {{
                $t("workspace_setting.lbl_workspace_name")[$orgModel]
                }}
              </label>
              <input
                type="text"
                :readonly="!can('update-facility')"
                class="form-control"
                id="clinic-name"
                placeholder
                v-model="params.name"
              />
              <small
                class="robo-14-400 text--red"
                v-if="is_submit && !params.name"
              >{{ $t("workspace_setting.lbl_valid_ws_name")[$orgModel] }}</small>
            </div>
            <div class="form-group col-6 mb-3">
              <label class="robo-16-500 mb-2" for="clinic-org">
                {{
                $t("workspace_setting.lbl_org_name")
                }}
              </label>
              <input
                type="text"
                :readonly="!can('update-facility')"
                class="form-control"
                id="clinic-org"
                :placeholder="$t('workspace_setting.lbl_org_name_plh')"
                v-model="params.org_name"
              />
            </div>
            <div class="form-group col-6 mb-3">
              <label class="robo-16-500 mb-2 required" for="clinic-specialist">Email</label>
              <input
                type="text"
                :readonly="!can('update-facility')"
                id="clinic-specialist"
                class="form-control"
                placeholder
                v-model="params.email"
              />
            </div>
            <div class="form-group col-6 mb-3">
              <label class="robo-16-500 mb-2" for="clinic-phone">
                {{
                $t("workspace_setting.lbl_phone_number")
                }}
              </label>
              <input
                type="text"
                :readonly="!can('update-facility')"
                class="form-control"
                id="clinic-phone"
                placeholder
                v-model="params.phone"
              />
            </div>
          </div>
          <!-- <div class="row mb-3">
                      <div class="col-12">
                        <label class="robo-16-500 required w-100" for="upload-logo">Logo</label>
                        </div>
                      <div class="col-6">
                        <label class="robo-20-400 input-logo pl-3" for="upload-logo">
                          <span class="border-right pr-2 w-90px">Chọn tệp</span>
                          <span class="pl-2 max-line-1 w-100pc-90px">{{obj_logo ? obj_logo.name : 'Chưa có tệp nào được chọn'}}</span>

                        </label>

                        <input type="file" class="d-none" id="upload-logo" accept="image/*" @change="changeLogo($event)">
                        <small class="robo-14-400 text--red pl-3">Logo này sẽ hiện trong các biên bản in ấn</small>

                      </div>
                      <div class="col-6">
                        <div class="logo-clinic" v-if="!params.print_image && url_logo" :style="`backgroundImage: url('${url_logo}');`"></div>
                        <div class="logo-clinic" v-if="params.print_image" :style="url_logo ? `backgroundImage: url('${url_logo}');` : `backgroundImage: url('${params.print_image}');`"></div>
                      </div>   
          </div>-->
          <div class="mb-3">
            <label class="robo-16-500 mb-2" for="clinic-address">
              {{
              $t("workspace_setting.lbl_address")
              }}
            </label>
            <input
              type="text"
              :readonly="!can('update-facility')"
              class="form-control"
              id="clinic-address"
              placeholder
              v-model="params.address"
            />
          </div>
          <div class="mb-3">
            <label class="robo-16-500 mb-2" for="clinic-specialist">
              {{
              $t("workspace_setting.lbl_specialist")
              }}
            </label>
            <input
              type="text"
              :readonly="!can('update-facility')"
              id="clinic-specialist"
              class="form-control"
              placeholder
              v-model="params.specialist"
            />
          </div>
          <div class="mb-3">
            <label class="robo-16-500 mb-2" for="clinic-specialist">
              {{
              $t("workspace_setting.lbl_description")
              }}
            </label>
            <textarea
              :readonly="!can('update-facility')"
              id="clinic-specialist"
              class="form-control"
              placeholder
              v-model="params.desc_full"
            />
          </div>
          <div class="mb-3">
            <label class="robo-16-500 mb-2 required">{{ $t('lbl_organization_model') }}</label>
            <select
              id="org-model"
              name="org-model"
              class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 pr-3 focus:outline-none w-full cursor-pointer"
              disabled
              v-model="params.is_medical"
            >
              <option
                value="1"
                :selected="params.is_medical === 1"
              >{{ $t('lbl_applicable_to_hospital') }}</option>
              <option
                value="2"
                :selected="params.is_medical === 2"
              >{{ $t('lbl_applicable_to_businesses') }}</option>
            </select>
          </div>
        </form>
        <div
          class="d-flex align-items-end justify-content-end"
          v-if="can('update-facility') || can('update-slot-info')"
        >
          <div
            v-if="loaded"
            :class="params.name && params.email ? '' : 'disabled'"
            class="bg-primary text-white cursor-pointer text-base flex items-center justify-center rounded-lg h-12 w-28 px-3 py-2 focus:outline-none"
            @click="updateDoctorClinic()"
          >{{ $t("workspace_setting.btn_update") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WorkTime from '../WorkTime'
export default {
  name: 'ClinicSetting',
  components: { WorkTime },
  data () {
    return {
      loaded: true,
      clinic_id: 0,
      qr_code: 'CLI|34',
      obj_image: '',
      doctor_clinic: {},
      work_time: null,
      work_time_new: null,
      params: {
        name: '',
        phone: '',
        address: '',
        specialist: '',
        open_time: '',
        close_time: '',
        avatar: '',
        email: '',
        print_image: '',
        desc_full: '',
        org_name: '',
        is_medical: ''
      },
      code: '',
      url_avatar: '',
      is_submit: false,
      obj_logo: '',
      url_logo: ''
    }
  },
  mounted () {
    this.clinic_id = this.$clinicId
    this.getDocterClinic()
  },
  methods: {
    getImageURL (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
    },
    changeAvatar (e) {
      let self = this
      self.obj_image = e.target ? e.target.files[0] : ''
      self.url_avatar = e.target.files[0]
        ? URL.createObjectURL(e.target.files[0])
        : ''
    },
    changeLogo (e) {
      let self = this
      self.obj_logo = e.target ? e.target.files[0] : ''
      self.url_logo = e.target.files[0]
        ? URL.createObjectURL(e.target.files[0])
        : ''
    },
    checkMyClinic () {
      var clinic = localStorage.getItem('global_clinic')
        ? JSON.parse(localStorage.getItem('global_clinic'))
        : ''
      if (clinic) {
        if (
          clinic.pivot.doctor_id === clinic.doctor_id ||
          this.can('update-facility')
        ) {
          return true
        } else {
          return false
        }
      }
    },
    async getDocterClinic () {
      let self = this
      try {
        self.loaded = false
        const result = await self.$rf
          .getRequest('DoctorRequest')
          .getDoctorClinics(self.clinic_id)
        let clinic = result.data
        console.log(clinic)
        self.doctor_clinic = clinic
        self.params.avatar = clinic ? clinic.avatar : ''
        self.params.name = clinic ? clinic.name : ''
        self.params.phone = clinic ? clinic.phone : ''
        self.params.address = clinic ? clinic.address : ''
        self.params.specialist = clinic ? clinic.specialist : ''
        self.params.email = clinic ? clinic.email : ''
        self.params.print_image = clinic ? clinic.print_image : ''
        self.params.org_name = clinic ? clinic.org_name : ''
        self.params.desc_full = clinic ? clinic.desc_full : ''
        self.qr_code = clinic ? clinic.qr_code : ''
        self.code = clinic ? clinic.code : ''
        self.params.is_medical = clinic?.organization
          ? clinic.organization.is_medical
          : 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loaded = true
      }
    },
    async updateDoctorClinic () {
      let self = this
      try {
        self.loaded = false
        self.is_submit = true
        if (self.params.name && self.params.email) {
          let params = self.params
          params.avatar = self.obj_image
          params.print_image = self.obj_logo
          var formData = new FormData()
          for (var key in params) {
            formData.append(key, params[key] || '')
          }
          await self.$rf
            .getRequest('DoctorRequest')
            .postClinicEdit(self.clinic_id, formData)
            .then(r => {
              self.$toast.open({
                message: this.$t('workspace_setting.lbl_update_succecss'),
                type: 'success'
              })
              self.getDocterClinic()
              this.is_submit = false
              this.backWS()
            })
        } else {
          alert(this.$t('workspace_setting.lbl_fill_data_msg'))
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loaded = true
      }
    },
    getCharAtFirst (name) {
      return name.substr(0, 1).toUpperCase()
    },
    copyText (id_element) {
      var code = document.getElementById(id_element)
      const range = document.createRange()
      var selection = window.getSelection()
      selection.removeAllRanges()
      range.selectNodeContents(code)
      selection.addRange(range)
      try {
        if (this.isSupport('copy')) {
          document.execCommand('copy')
          selection.removeAllRanges()
          this.$toast.open({
            message: this.$t('workspace_setting.lbl_copy_success'),
            type: 'success'
          })
        } else {
          this.$toast.open({
            message: this.$t('workspace_setting.lbl_copy_not_support'),
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    isSupport (cmd) {
      return document.queryCommandSupported(cmd)
    },
    backWS () {
      this.$router.push({ name: 'ClinicPersonnelManage' })
    }
  }
}
</script>
<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: 35% 65%;
}

.container-grid-50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.margin-5 {
  margin: 20px 5px;
}

.item {
  margin: 5px;
  color: black;
  line-height: 30px;
}

.w-18h-20 {
  width: 18px;
  height: 20px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.border-avatar {
  width: 228px;
  height: 228px;
}
.add-avatar {
  text-align: center;
}

.avatar-clinic {
  width: 228px;
  height: 228px;
  background-color: #eef3ff;
  border-radius: 228px;
  border: 0.5px solid #eef3ff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.logo-clinic {
  width: 60px;
  height: 60px;
  background-color: #eef3ff;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.avatar-no-clinic {
  width: 228px;
  height: 228px;
  background-color: #99bdff;
  border-radius: 228px;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  line-height: 228px;
}
.input-logo {
  background-color: #f6f8fb !important;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
.form-control {
  font-size: 20px;
  border-radius: 6px;
}
.w-90px {
  width: 90px;
}
.w-100pc-90px {
  width: calc(100% - 100px);
}
@media (max-width: 600px) {
  .container-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .container-grid-50-50 {
    display: grid;
    grid-template-columns: 1fr;
  }
}
select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 50%;
}
select option {
  display: block;
}
</style>