<template>
	<div class="container-window mt-3 mr-3 border bd-e4 px-3">
		<HeaderWindow @onClose="onClose" :title="test_item && test_item.test_result_name" :show_calll="true" />
    <TestsTable
      :documents="documents"
      :list_tests="person_tests"
      @getTestsByDiseases="getPersonDiseasesByIdPersonTest"
    />
	</div>
</template>
<script>
import PersonProfile from '../Diseases/PersonProfile.vue'
import HeaderWindow from '../Diseases/HeaderWindow.vue'
import TypeBar from '../Diseases/TypeBar.vue'
import Pagination from '../Pagination.vue'
import TestsTable from '../TestsGroup/TestsTable.vue'
export default {

  name: 'CmsListTest',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination, TestsTable },
  props: ['test'],
  data () {
    return {
      title: 'Xét nghiệm',
      person_diseases_id: '',
      current_page: 1,
      person_tests: null,
      test_item: null,
      documents: []
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.getPersonDiseasesByIdPersonTest()
    self.getPersonTestResultByID()
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/admin/diseases/${this.$route?.params?.id}/detail` })
    },
    async getPersonDiseasesByIdPersonTest () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          person_test_result_id: self.$route.query.test,
          sort: 'created_at',
          sort_by: 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getPersonDiseasesByIdPersonTest(self.$route?.params?.id, params).then(res => {
          if (res && res.data) {
            self.person_tests = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getPersonTestResultByID () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonTestResultByID(self.$route.query.test).then(res => {
          if (res && res.data) {
            self.test_item = res.data
            self.documents = res.data.document
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>