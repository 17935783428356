<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-center mb-1">
        <button
          class="btn bg-pri bd-pri text-white"
          type="button"
          @click="showSignUp(true)"
          v-if="(isOwnerWorkspace || isOwnerOrAdmin)"
        >+ {{ $t("workspace_setting.btn_add_staff")[$orgModel] }}</button>
      </div>
      <div class="col-12 table-responsive" v-if="can('view-doctor-list') || can('view-staff-list')">
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>{{ $t("workspace_setting.lbl_hd_info") }}</th>
              <th>{{ $t("workspace_setting.lbl_hd_title") }}</th>
              <th>{{ $t("workspace_setting.lbl_hd_contact") }}</th>
              <th>{{ $t("health_book_page.lbl_hd_status") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="invitations && invitations.count">
            <tr
              class="table-tr mb-1"
              v-for="invitation in invitations.data"
              style="min-height: 70px"
              :key="invitation.id"
            >
              <td :data-label="$t('workspace_setting.lbl_hd_info')">
                <div class="d-flex py-1 align-items-center pl-3">
                  <div style="min-width: 40px">
                    <span
                      class="avatar avatar-sm avatar-rounded bg-dark text-white"
                      style="height: 30px; width: 30px"
                    >
                      {{
                      invitation && invitation.name
                      ? invitation.name.charAt(0)
                      : invitation && invitation.email
                      ? invitation.email.charAt(0)
                      : ""
                      }}
                    </span>
                  </div>
                  <div class="flex-fill">
                    <p class="robo-14-500 txt-pri p-0 m-0">
                      {{
                      invitation && invitation.email ? invitation.email : ""
                      }}
                    </p>
                    <p
                      class="robo-14-500 txt-pri p-0 m-0"
                    >{{ invitation && invitation.name ? invitation.name : "" }}</p>
                  </div>
                </div>
              </td>
              <td :data-label="$t('workspace_setting.lbl_hd_title')">
                <span
                  v-if="
                    invitation &&
                    (invitation.type === 0 || invitation.type === null)
                  "
                  class="fs-14"
                  style="color: #20419b"
                >{{ $t("workspace_setting.lbl_doctor") }}</span>
                <span
                  v-if="invitation && invitation.type === 1"
                  class="fs-14 txt-grey-600"
                >{{ $t("workspace_setting.lbl_health_care_staff") }}</span>
                <span
                  v-if="invitation && invitation.type === 2"
                  class="fs-14 txt-grey-600"
                >{{ $t("workspace_setting.lbl_specialist_doctor") }}</span>
                <span
                  v-if="invitation && invitation.type === 3"
                  class="fs-14 txt-grey-600"
                >{{ $t("workspace_setting.lbl_nurse") }}</span>
                <span
                  v-if="invitation && invitation.type === 4"
                  class="fs-14 txt-grey-600"
                >{{ $t("workspace_setting.lbl_hcp") }}</span>
              </td>
              <td data-label="Liên hệ">
                <span class="fs-14 txt-grey-600">
                  {{
                  invitation && invitation.phone_number
                  ? formatPhoneNumber(invitation.phone_number)
                  : ""
                  }}
                </span>
              </td>
              <td :data-label="$t('health_book_page.lbl_hd_status')">
                <span
                  class="fs-14"
                  :class="
                    !invitation.status
                      ? 'text-warning'
                      : invitation.status === 1
                      ? 'txt-pri'
                      : invitation.status === 2
                      ? 'txt-err-700'
                      : ''
                  "
                >
                  {{
                  !invitation.status
                  ? $t("workspace_setting.lbl_wait")
                  : invitation.status === 1
                  ? $t("workspace_setting.lbl_join")
                  : invitation.status === 2
                  ? $t("workspace_setting.lbl_reject")
                  : ""
                  }}
                </span>
              </td>
              <td data-label>
                <div align="right" v-if="!invitation.status && isOwnerWorkspace">
                  <button
                    class="btn bg--red-corl text--red border-0"
                    @click="deleteInvitations(invitation && invitation.id)"
                  >{{ $t("workspace_setting.btn_delete") }}</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <Pagination
          v-if="invitations && invitations.count"
          :items="invitations"
          :current_page="current_page"
          @onRefresh="getInvited"
        ></Pagination>-->
        <AppPagination
          :paginationProps="{
            currentPage: current_page,
            totalItems,
            pageSize: limit,
          }"
          @onChangePagination="(data) => getInvited(data)"
        />
        <div v-if="invitations && !invitations.count">
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden">
            <img
              src="../../../public/assets/images/not-found-search.png"
              class="img-responsive p-0"
              alt
            />
            <p
              class="robo-16-400 text-black"
            >{{ $t(search ? "lbl_no_records_match_your_search" : "lbl_no_invitation_yet") }}</p>
          </div>
        </div>
      </div>
      <div v-if="!can('view-doctor-list') && !can('view-staff-list')">
        <p
          class="mb-0 text-center text-red robo-20-500"
        >{{ $t("workspace_setting.lbl_msg_no_permission") }}</p>
      </div>
    </div>
    <SignUpStaff v-if="isOwnerWorkspace || isOwnerOrAdmin" :wsId="wsId" @refresh="getInvited()"></SignUpStaff>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import Pagination from '../Pagination'
import SignUpStaff from './SignUpStaff'
import { AppPagination } from '../Common'

export default {
  name: 'InvitedDoctors',
  components: { Pagination, SignUpStaff, AppPagination },
  props: {
    wsId: Number,
    wsInfo: Object,
    isOrgPage: Boolean
  },
  data () {
    return {
      invitations: [],
      loading: true,
      current_page: 1,
      search: '',
      wsp_id: this.wsId || this.$clinicId || null,
      limit: 15,
      totalItems: 0,
      isOwnerOrAdmin: false
    }
  },
  computed: {
    isOwnerWorkspace () {
      let clinic = this.$globalClinic || this.wsInfo
      let user = this.$user
      if (!clinic || !clinic.id || !user || !user.doctor) return
      return clinic.doctor_id === user.doctor.id
    }
  },
  mounted () {
    this.wsp_id = this.wsId || this.$clinicId
    this.getInvited()
    this.isOwnerOrAdmin = this.checkRoleOwnerOrg(this.$user)
  },
  methods: {
    async getInvited (data) {
      let self = this
      self.loading = true
      let params = {
        limit: data?.pageSize || self.limit,
        page: data?.currentPage || self.current_page
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getInvitedDoctorWs(self.wsp_id, params)
        .then(resp => {
          self.invitations = resp.data
          self.totalItems = resp.data?.total || 0
          self.limit = Number(resp.data?.per_page) || 0
          self.current_page = resp.data?.current_page || 1
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          self.loading = false
        })
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    formatPhoneNumber (number) {
      // Filter only numbers from the input
      let cleaned = ('' + number).replace(/\D/g, '')
      // Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      return match ? match[1] + '-' + match[2] + '-' + match[3] : ''
    },
    async deleteInvitations (id) {
      let self = this
      try {
        var r = confirm('Bạn chắc chắn xoá người này?')
        if (r) {
          if (id) {
            await self.$rf
              .getRequest('DoctorRequest')
              .deleteInvitedDoctorWs(id)
              .then(res => {
                self.getInvited()
                self.$toast.open({
                  message: 'Xóa thành công',
                  type: 'success'
                })
              })
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    showSignUp (show) {
      window.$('#modal__sign').modal(show ? 'show' : 'hide')
    }
  }
}
</script>
<style lang="css" scoped></style>
