<template>
  <div>
      <div class="mx-3 mb-3 d-flex justify-content-between py-3 border-bottom">
          <h3 class="font-weight-bold">Biểu phí</h3>
            <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="goToList"
            class="cursor-pointer"
          >
            <path
              d="M6 18.4998L17.9998 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 18.4998L6 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
         </div>
    <div class="form-group margin-5">
      <label class="robo-16-500 mb-2">Lịch hẹn</label>
      <div class="row">
        <div class="col-6">
          <label class="robo-14-500 required" for="time"
            >Chi phí mỗi ca tư vấn ( vnđ )</label
          >
          <input
            type="text"
            :readonly="!can('update-slot-info')"
            id="price"
            class="form-control"
            placeholder=""
            v-model="distance.price"
          />
        </div>
        <div class="col-6">
          <label class="robo-14-500 required" for="time"
            >Thời gian mỗi ca tư vấn ( phút )</label
          >
          <input
            type="text"
            :readonly="!can('update-slot-info')"
            id="time"
            class="form-control"
            placeholder=""
            v-model="distance.time"
          />
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-end justify-content-end py-3"
      v-if="can('update-facility') || can('update-slot-info')"
    >
      <div v-if="!loading" class="btn btn-l bg-pri bd-pri text-white" @click="changeTime()">
        Cập nhật
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'TariffSetting',
  data () {
    return {
      distance: {
        price: 0,
        distance: 15
      },
      loading: false
    }
  },
  mounted () {
    if (this.$clinicId) this.getDocterClinic()
  },
  methods: {
    async getDocterClinic () {
      let self = this
      this.loading = true
      try {
        const result = await self.$rf.getRequest('DoctorRequest').getDoctorClinics(this.$clinicId)
        let clinic = result.data
        self.distance = {
          time: clinic && clinic.distance_time ? self.changeMinutes(clinic.distance_time) : 15,
          price: clinic && clinic.distance_price ? clinic.distance_price : 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async changeTime () {
      let self = this
      let times = this.distance.time
      let price = this.distance.price
      try {
        this.loading = true
        var params = {
          clinic_id: this.$clinicId,
          distance_time: times ? self.hhmm(times) : self.hhmm(15),
          distance_price: price || 0
        }
        await self.$rf.getRequest('DoctorRequest').postDistanceTime(params).then((r) => {
          self.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
          var result = this.$globalClinic
          result.distance_time = params.distance_time + ':00'
          result.distance_price = parseInt(price)
          appUtils.setDoctorClinic(result)
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    pad (num) {
      return ('0' + num).slice(-2)
    },
    hhmm (minutes) {
      var hours = Math.floor(minutes / 60)
      minutes = minutes % 60
      return `${this.pad(hours)}:${this.pad(minutes)}`
    },
    changeMinutes (time) {
      var arr = time.match(/^(\d{2}):(\d{2}):(\d{2})$/)
      return parseInt(arr[1]) * 60 + parseInt(arr[2])
    },
    goToList () {
      this.$router.push({ path: '/ws-mgmt' })
    }
  }
}
</script>

<style scoped>
</style>