<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-center mb-1">
        <button
          class="btn bg-pri bd-pri text-white"
          type="button"
          @click="showSignUp(true)"
          v-if="isOwnerWorkspace || isOwnerOrAdmin"
        >
          + {{ $t("workspace_setting.btn_add_staff") }}
        </button>
      </div>
      <div
        class="col-12 table-responsive"
        v-if="can('view-doctor-list') || can('view-staff-list')"
      >
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>{{ $t("workspace_setting.lbl_hd_info") }}</th>
              <th>{{ $t("workspace_setting.lbl_hd_title") }}</th>
              <th>{{ $t("workspace_setting.lbl_hd_contact") }}</th>
              <!-- <th>Phân quyền</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody v-if="doctors && doctors.count">
            <tr
              class="table-tr mb-1"
              v-for="item in doctors.data"
              style="min-height: 70px"
              :key="item.doctor_id"
            >
              <td
                :data-label="$t('workspace_setting.lbl_hd_info')"
                @click="goToProfile(item && item.doctor_id)"
              >
                <div class="d-flex py-1 align-items-center pl-3">
                  <div style="min-width: 40px">
                    <span
                      v-if="
                        item &&
                        item.doctor &&
                        item.doctor.user &&
                        item.doctor.user.avatar
                      "
                      class="avatar avatar-sm avatar-rounded"
                      :style="
                        'background-image: url(' +
                        getImageURL(item.doctor.user.avatar) +
                        ')'
                      "
                      style="height: 30px; width: 30px"
                    ></span>
                    <span
                      v-if="
                        item &&
                        item.doctor &&
                        item.doctor.user &&
                        !item.doctor.user.avatar
                      "
                      class="avatar avatar-sm avatar-rounded bg-dark text-white"
                      style="height: 30px; width: 30px"
                    >
                      {{
                        item && item.doctor && item.doctor.name
                          ? item.doctor.name.charAt(0)
                          : ""
                      }}
                    </span>
                  </div>
                  <div class="flex-fill">
                    <p
                      v-if="item && item.doctor && !item.doctor.name"
                      class="fs-14 fw-500 txt-pri p-0 m-0"
                    >
                      {{
                        item &&
                        item.doctor &&
                        item.doctor.user &&
                        item.doctor.user.email
                          ? item.doctor.user.email
                          : ""
                      }}
                    </p>
                    <p v-else class="fs-14 fw-500 txt-pri p-0 m-0">
                      {{
                        item && item.doctor && item.doctor.name
                          ? item.doctor.name
                          : ""
                      }}
                    </p>
                  </div>
                </div>
              </td>
              <td :data-label="$t('workspace_setting.lbl_hd_title')">
                <span
                  v-if="
                    item &&
                    item.doctor &&
                    (item.doctor.type === 0 || item.doctor.type === null)
                  "
                  class="fs-14"
                  style="color: #20419b"
                  >{{ $t("workspace_setting.lbl_doctor") }}</span
                >
                <span
                  v-if="item && item.doctor && item.doctor.type === 1"
                  class="fs-14"
                  style="color: #20419b"
                  >{{ $t("workspace_setting.lbl_health_care_staff") }}</span
                >
                <span
                  v-if="item && item.doctor && item.doctor.type === 2"
                  class="fs-14"
                  style="color: #20419b"
                  >{{ $t("workspace_setting.lbl_specialist_doctor") }}</span
                >
                <span
                  v-if="item && item.doctor && item.doctor.type === 3"
                  class="fs-14"
                  style="color: #20419b"
                  >{{ $t("workspace_setting.lbl_nurse") }}</span
                >
                <span
                  v-if="item && item.doctor && item.doctor.type === 4"
                  class="fs-14"
                  style="color: #20419b"
                  >{{ $t("workspace_setting.lbl_hcp") }}</span
                >
              </td>
              <td data-label="Liên hệ">
                <button
                  type="button"
                  class="btn bg-pri text-white mr-2 border-0"
                  v-if="$user.id !== (item.doctor && item.doctor.user_id)"
                  @click="messageTo(item.doctor.user_id, $event)"
                >
                  <span>Chat</span>
                  <div
                    class="spinner-border mx-2 loading-btn-icon"
                    role="status"
                  >
                    <div class="sr-only">Loading...</div>
                  </div>
                </button>
              </td>
              <td data-label>
                <div class="d-flex justify-content-end">
                  <button
                    class="btn bg--red-corl text--red border-0"
                    v-if="
                      my_dr_id !== item.doctor_id &&
                      (isOwnerWorkspace || isOwnerOrAdmin)
                    "
                    @click="deletePersonWorkspace(item && item.doctor_id)"
                  >
                    {{ $t("workspace_setting.btn_delete") }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <AppPagination
          :paginationProps="{
            currentPage: current_page,
            totalItems,
            pageSize: limit,
          }"
          @onChangePagination="(data) => getDoctorWsp(data)"
        />

        <!-- <Pagination v-if="doctors && doctors.count" :items="doctors" :current_page="current_page"
        @onRefresh="getDoctorWsp"></Pagination>-->
        <div v-if="doctors && !doctors.count">
          <div
            class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden"
          >
            <img
              src="../../../public/assets/images/not-found-search.png"
              class="img-responsive p-0"
              alt
            />
            <p class="robo-16-400 text-black">
              {{
                search
                  ? $t("workspace_setting.lbl_msg_notfound")
                  : $t("workspace_setting.lbl_msg_no_staff")
              }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="!can('view-doctor-list') && !can('view-staff-list')">
        <p class="mb-0 text-center text-red robo-20-500">
          {{ $t("workspace_setting.lbl_msg_no_permission") }}
        </p>
      </div>
    </div>
    <SignUpStaff
      v-if="isOwnerWorkspace || isOwnerOrAdmin"
      :wsId="wsId"
      @refresh="getDoctorWsp()"
    ></SignUpStaff>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import SignUpStaff from './SignUpStaff'
import { Tooltip } from 'element-ui'
import { AppPagination } from '../Common'

export default {
  name: 'ListStaff.vue',
  components: { SignUpStaff, Tooltip, AppPagination },
  props: {
    wsId: Number,
    wsInfo: Object,
    isOrgPage: Boolean
  },
  data () {
    return {
      doctors: [],
      roles: [],
      loading: true,
      wsp_id: this.wsId || this.$clinicId || null,
      my_dr_id: null,
      current_page: 1,
      search: '',
      wsp_dtr_id: null,
      isOwnerOrAdmin: false,
      totalItems: 0,
      limit: 15
    }
  },
  computed: {
    isOwnerWorkspace () {
      let clinic = this.$globalClinic || this.wsInfo
      let user = this.$user
      if (!clinic || !clinic.id || !user || !user.doctor) return
      return clinic.doctor_id === user.doctor.id
    }
  },
  mounted () {
    this.wsp_id = this.wsId || this.$clinicId
    this.wsp_dtr_id = this.$globalClinic?.doctor_id
    let user = appUtils.getLocalUser()
    this.my_dr_id = user && user.doctor && user.doctor.id
    this.getDoctorWsp()
    this.getRoles()
    this.isOwnerOrAdmin = this.checkRoleOwnerOrg(this.$user)
  },
  methods: {
    goToProfile (id) {
      if (!id) return
      this.$router.push({ name: 'DoctorProfile', params: { id: id } })
    },
    canChangeRole (dtr) {
      if (!dtr) return
      if (!dtr.type) {
        return this.can('update-doctor-rule')
      } else {
        return this.can('update-staff-rule')
      }
    },
    async getDoctorWsp (data) {
      let self = this
      self.loading = true
      let params = {
        limit: data?.pageSize || self.limit,
        page: data?.currentPage || self.current_page
      }
      if (!this.can('view-doctor-list') && !this.can('view-staff-list')) return
      if (!this.can('view-doctor-list') && this.can('view-staff-list')) {
        params.type = 'staff'
      }
      if (this.can('view-doctor-list') && !this.can('view-staff-list')) {
        params.type = 'dtr'
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getDoctorWorkspaceV2(self.wsp_id, params)
        .then((resp) => {
          self.doctors = resp.data
          self.totalItems = resp.data?.total || 0
          self.limit = Number(resp.data?.per_page) || 0
          self.current_page = resp.data?.current_page || 1
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          self.loading = false
        })
    },
    async getRoles () {
      let self = this
      self.loading = true
      let params = {
        limit: 100,
        doctor_id: 68
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getWorkspaceRoles(self.wsp_id, params)
        .then((resp) => {
          self.roles = resp.data
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          self.loading = false
        })
    },
    async changeRole (event, doctor) {
      let self = this
      let role = event.target.value
      if (event.target.value !== doctor.role_id) {
        let params = {
          doctor_id: doctor.id || '',
          role_id: role || ''
        }

        await self.$rf
          .getRequest('DoctorRequest')
          .changeDoctorRole(self.wsp_id, params)
          .then((resp) => {
            self.getDoctorWsp()
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            self.loading = false
          })
      }
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    formatPhoneNumber (number) {
      // Filter only numbers from the input
      let cleaned = ('' + number).replace(/\D/g, '')
      // Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      return match ? match[1] + '-' + match[2] + '-' + match[3] : ''
    },
    isThisRole (id) {
      let roles = this.roles
      if (roles && roles.data && roles.total) {
        return roles.data.findIndex((rl) => rl.id === id) > -1
      }
    },
    async deletePersonWorkspace (id) {
      const self = this
      try {
        var r = confirm(this.$t('workspace_setting.lbl_msg_remove'))
        if (r) {
          if (id) {
            var params = {
              doctor_id: id
            }

            await self.$rf
              .getRequest('DoctorRequest')
              .postDoctorClinicDelete(self.wsp_id, params)
              .then((res) => {
                self.getDoctorWsp()
              })
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    showSignUp (show) {
      window.$('#modal__sign').modal(show ? 'show' : 'hide')
    },
    async messageTo (id, e) {
      let self = this
      let tg = e.currentTarget
      tg.classList.add('loading-btn')
      await this.$rf
        .getRequest('AuthRequest')
        .directChatTo({ partner_user_id: id }, null, false)
        .then((r) => {
          this.$reloadChat++
          if (r?.data?.twilio_conv_id) {
            self.$router.push({
              name: 'ChatPage',
              query: { sid: r?.data?.twilio_conv_id }
            })
          }
        })
        .finally(() => {
          tg.classList.remove('loading-btn')
        })
    }
  }
}
</script>
<style lang="css" scoped>
.loading-btn-icon {
  display: none;
}

.loading-btn .loading-btn-icon {
  display: block !important;
}

.loading-btn {
  pointer-events: none !important;
  color: white;
  border-color: #98a2b3 !important;
}

.loading-btn span {
  display: none;
}
</style>
