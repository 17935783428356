<template>
  <div class="row">
    <div class="mr-4 mb-3 d-flex justify-content-between py-3">
      <h3 class="font-weight-bold">{{ $t('lbl_monitoring_cate') }}</h3>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="goToList"
        class="cursor-pointer"
      >
        <path
          d="M6 18.4998L17.9998 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 18.4998L6 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="telemedicine-management pr-3 pt-3">
      <div class="row mb-3">
        <div class="col-6 d-flex">
          <div class="d-flex align-items-center mr-4 cursor-pointer">
            <input
              type="radio"
              v-model="status"
              :value="null"
              class="mr-2"
              @change="getStats()"
              id="s1"
            />
            <label for="s1" class="mb-0">{{ $t('lbl_all') }}</label>
          </div>
          <div class="d-flex align-items-center mr-4 cursor-pointer">
            <input
              type="radio"
              v-model="status"
              :value="1"
              class="mr-2"
              @change="getStats()"
              id="s2"
            />
            <label for="s2" class="mb-0">{{ $t('lbl_active') }}</label>
          </div>
          <div class="d-flex align-items-center mr-4 cursor-pointer">
            <input
              type="radio"
              v-model="status"
              :value="0"
              class="mr-2"
              @change="getStats()"
              id="s3"
            />
            <label for="s3" class="mb-0">{{ $t('lbl_inactive') }}</label>
          </div>
        </div>
        <div class="col-6 mb-1">
          <div class="d-flex justify-content-end">
            <button
              class="input-group-text btn btn-sm bg-pri bd-pri text-white"
              @click="showAdd(true)"
            >{{ $t('lbl_add_new') }} +</button>
          </div>
        </div>
      </div>
      <div class="card radius-10 border-0">
        <!-- <div class="d-flex justify-content-end mb-3">
          <select
            name=""
            id=""
            v-model="sort_by"
            class="p-1 form-select px-3 border mx-3"
            @change="getStats()"
          >
            <option value="desc">Mới nhất trước</option>
            <option value="asc">Cũ nhất trước</option>
          </select>
          <div class="input-group">
            <input
              class="form-control h-100 border"
              type="text"
              v-model="search"
              @keyup.enter="onSearch()"
              @keyup.delete="onSearchDelete()"
            >
            <button
              class="input-group-text btn btn-light"
              @click="onSearch()"
            >Tìm kiếm</button>
          </div>
        </div>-->
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>{{ $t('lbl_monitoring_cate_name') }}</th>
              <th>Code</th>
              <th>{{ $t('lbl_unit') }}</th>
              <th>
                <div class="d-flex justify-content-end"></div>
              </th>
            </tr>
          </thead>
          <tbody v-if="stats && stats.data && !loading">
            <tr v-for="stat in stats.data" :key="stat.id">
              <td data-title="Tên chỉ số">
                <span>{{stat.name}}</span>
              </td>
              <td data-title="Code">
                <span>{{stat.code}}</span>
              </td>
              <td data-title="Đơn vị">
                <span>{{stat.unit}}</span>
              </td>
              <td>
                <div class="d-flex d-flex justify-content-end" v-if="clinic_id == stat.clinic_id">
                  <button
                    class="btn btn-sm bg-pri bd-pri text-white mr-2"
                    @click="onEdit(stat)"
                  >{{ $t('lbl_edit') }}</button>
                  <button
                    class="btn btn-sm"
                    :class="stat.status ? 'btn--ghost txt-black-gray' : 'bg-pri bd-pri text-white'"
                    @click="onStatus(stat.id)"
                  >{{$t(stat.status ? 'lbl_inactive' : 'lbl_active')}}</button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="loading" class="h-100" style="min-height: 600px;">
            <tr>
              <td colspan="6" class="text-center py-5">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="!loading && (!stats || !stats.data || !stats.data.length)"
            class="h-100"
            style="min-height: 600px;"
          >
            <tr>
              <td colspan="6" class="text-center py-5">
                <p class="robo-16-500">Chưa có chỉ số nào</p>
              </td>
            </tr>
          </tbody>
        </table>
        <AppPagination :paginationProps="pagination" @onChangePagination="getStats" />
        <!-- <Pagination
          class="Content"
          v-show="stats && stats.count"
          v-if="stats"
          :items="stats"
          :current_page="current_page"
          @onRefresh="getStats"
        ></Pagination>-->
      </div>
    </div>
    <ModalCreateStats @onCreate="onCreate" />
    <ModalUpdateStats @onUpdate="onUpdate" :edit="edit" />
  </div>
</template>

<script>
import ModalCreateStats from '../../components/Stats/ModalCreateStats.vue'
import ModalUpdateStats from '../../components/Stats/ModalUpdateStats.vue'
import { AppPagination } from '@/components/Common'

export default {
  name: 'StatsList',
  components: { AppPagination, ModalCreateStats, ModalUpdateStats },
  data () {
    return {
      stats: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      current_page: 1,
      edit: null,
      clinic_id: null,
      status: null,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },
  mounted () {
    let self = this
    self.clinic_id = this.$clinicId
    self.getStats()
  },
  methods: {
    async getStats (data) {
      let self = this
      self.loading = true
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          clinic_id: self.clinic_id,
          limit: data?.pageSize || this.pagination.pageSize,
          page: data?.currentPage || this.pagination.currentPage
        }
        if (self.status !== null) {
          params.status = self.status
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getSurvivalStats(params)
          .then(res => {
            if (res && res.data) {
              self.stats = res.data
              this.pagination = {
                currentPage:
                  res.data?.current_page || this.pagination.currentPage,
                pageSize: res.data?.count || this.pagination.pageSize,
                totalItems: res.data?.total || 0
              }
            }
          })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async onCreate (data) {
      let self = this
      if (data) {
        data.clinic_id = self.clinic_id
        self.$rf
          .getRequest('DoctorRequest')
          .createSurvivalStats(data)
          .then(resp => {
            self.search = ''
            self.sort = 'created_at'
            self.sort_by = 'desc'
            self.current_page = 1
            self.showAdd(false)
            self.getStats()
          })
      }
    },
    async onUpdate (data) {
      let self = this
      if (data) {
        data.clinic_id = self.clinic_id
        self.$rf
          .getRequest('DoctorRequest')
          .updateSurvivalStats(self.edit.id, data)
          .then(resp => {
            self.showEdit(false)
            self.edit = null
            self.getStats()
          })
      }
    },
    async onStatus (id) {
      let self = this
      let r = confirm(
        'Bạn có chắc chắn muốn ẩn chỉ số này khỏi danh sách chọn? Chỉ số này sẽ vẫn hiển thị tại các nơi đã được sử dụng.'
      )
      if (!r) return
      if (id) {
        self.$rf
          .getRequest('DoctorRequest')
          .updateSurvivalStatStatus(id)
          .then(resp => {
            self.getStats()
          })
      }
    },
    onSearch () {
      let self = this
      self.getStats()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getStats()
      }
    },
    formatDDMMYYYY (date) {
      return window.moment(date).format('HH:mm DD/MM/YY')
    },
    onEdit (stat) {
      this.edit = stat
      this.showEdit(true)
    },
    showEdit (show) {
      window.$('#ModalUpdateStats').modal(show ? 'show' : 'hide')
    },
    showAdd (show) {
      window.$('#ModalCreateStats').modal(show ? 'show' : 'hide')
    },
    goToList () {
      this.$router.push({ path: '/ws-mgmt' })
    }
  }
}
</script>

<style scoped>
.btn-sm {
  width: 100px;
}
</style>