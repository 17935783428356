var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end align-items-center mb-1"},[((_vm.isOwnerWorkspace || _vm.isOwnerOrAdmin))?_c('button',{staticClass:"btn bg-pri bd-pri text-white",attrs:{"type":"button"},on:{"click":function($event){return _vm.showSignUp(true)}}},[_vm._v("+ "+_vm._s(_vm.$t("workspace_setting.btn_add_staff")[_vm.$orgModel]))]):_vm._e()]),(_vm.can('view-doctor-list') || _vm.can('view-staff-list'))?_c('div',{staticClass:"col-12 table-responsive"},[_c('table',{staticClass:"table table-vcenter table-mobile-md card-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hd_info")))]),_c('th',[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hd_title")))]),_c('th',[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hd_contact")))]),_c('th',[_vm._v(_vm._s(_vm.$t("health_book_page.lbl_hd_status")))]),_c('th')])]),(_vm.invitations && _vm.invitations.count)?_c('tbody',_vm._l((_vm.invitations.data),function(invitation){return _c('tr',{key:invitation.id,staticClass:"table-tr mb-1",staticStyle:{"min-height":"70px"}},[_c('td',{attrs:{"data-label":_vm.$t('workspace_setting.lbl_hd_info')}},[_c('div',{staticClass:"d-flex py-1 align-items-center pl-3"},[_c('div',{staticStyle:{"min-width":"40px"}},[_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white",staticStyle:{"height":"30px","width":"30px"}},[_vm._v(" "+_vm._s(invitation && invitation.name ? invitation.name.charAt(0) : invitation && invitation.email ? invitation.email.charAt(0) : "")+" ")])]),_c('div',{staticClass:"flex-fill"},[_c('p',{staticClass:"robo-14-500 txt-pri p-0 m-0"},[_vm._v(" "+_vm._s(invitation && invitation.email ? invitation.email : "")+" ")]),_c('p',{staticClass:"robo-14-500 txt-pri p-0 m-0"},[_vm._v(_vm._s(invitation && invitation.name ? invitation.name : ""))])])])]),_c('td',{attrs:{"data-label":_vm.$t('workspace_setting.lbl_hd_title')}},[(
                  invitation &&
                  (invitation.type === 0 || invitation.type === null)
                )?_c('span',{staticClass:"fs-14",staticStyle:{"color":"#20419b"}},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_doctor")))]):_vm._e(),(invitation && invitation.type === 1)?_c('span',{staticClass:"fs-14 txt-grey-600"},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_health_care_staff")))]):_vm._e(),(invitation && invitation.type === 2)?_c('span',{staticClass:"fs-14 txt-grey-600"},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_specialist_doctor")))]):_vm._e(),(invitation && invitation.type === 3)?_c('span',{staticClass:"fs-14 txt-grey-600"},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_nurse")))]):_vm._e(),(invitation && invitation.type === 4)?_c('span',{staticClass:"fs-14 txt-grey-600"},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hcp")))]):_vm._e()]),_c('td',{attrs:{"data-label":"Liên hệ"}},[_c('span',{staticClass:"fs-14 txt-grey-600"},[_vm._v(" "+_vm._s(invitation && invitation.phone_number ? _vm.formatPhoneNumber(invitation.phone_number) : "")+" ")])]),_c('td',{attrs:{"data-label":_vm.$t('health_book_page.lbl_hd_status')}},[_c('span',{staticClass:"fs-14",class:!invitation.status
                    ? 'text-warning'
                    : invitation.status === 1
                    ? 'txt-pri'
                    : invitation.status === 2
                    ? 'txt-err-700'
                    : ''},[_vm._v(" "+_vm._s(!invitation.status ? _vm.$t("workspace_setting.lbl_wait") : invitation.status === 1 ? _vm.$t("workspace_setting.lbl_join") : invitation.status === 2 ? _vm.$t("workspace_setting.lbl_reject") : "")+" ")])]),_c('td',{attrs:{"data-label":""}},[(!invitation.status && _vm.isOwnerWorkspace)?_c('div',{attrs:{"align":"right"}},[_c('button',{staticClass:"btn bg--red-corl text--red border-0",on:{"click":function($event){return _vm.deleteInvitations(invitation && invitation.id)}}},[_vm._v(_vm._s(_vm.$t("workspace_setting.btn_delete")))])]):_vm._e()])])}),0):_vm._e()]),_c('AppPagination',{attrs:{"paginationProps":{
          currentPage: _vm.current_page,
          totalItems: _vm.totalItems,
          pageSize: _vm.limit,
        }},on:{"onChangePagination":(data) => _vm.getInvited(data)}}),(_vm.invitations && !_vm.invitations.count)?_c('div',[_c('div',{staticClass:"col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden"},[_c('img',{staticClass:"img-responsive p-0",attrs:{"src":require("../../../public/assets/images/not-found-search.png"),"alt":""}}),_c('p',{staticClass:"robo-16-400 text-black"},[_vm._v(_vm._s(_vm.$t(_vm.search ? "lbl_no_records_match_your_search" : "lbl_no_invitation_yet")))])])]):_vm._e()],1):_vm._e(),(!_vm.can('view-doctor-list') && !_vm.can('view-staff-list'))?_c('div',[_c('p',{staticClass:"mb-0 text-center text-red robo-20-500"},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_msg_no_permission")))])]):_vm._e()]),(_vm.isOwnerWorkspace || _vm.isOwnerOrAdmin)?_c('SignUpStaff',{attrs:{"wsId":_vm.wsId},on:{"refresh":function($event){return _vm.getInvited()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }