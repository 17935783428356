var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end align-items-center mb-1"},[(_vm.isOwnerWorkspace || _vm.isOwnerOrAdmin)?_c('button',{staticClass:"btn bg-pri bd-pri text-white",attrs:{"type":"button"},on:{"click":function($event){return _vm.showSignUp(true)}}},[_vm._v(" + "+_vm._s(_vm.$t("workspace_setting.btn_add_staff"))+" ")]):_vm._e()]),(_vm.can('view-doctor-list') || _vm.can('view-staff-list'))?_c('div',{staticClass:"col-12 table-responsive"},[_c('table',{staticClass:"table table-vcenter table-mobile-md card-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hd_info")))]),_c('th',[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hd_title")))]),_c('th',[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hd_contact")))]),_c('th')])]),(_vm.doctors && _vm.doctors.count)?_c('tbody',_vm._l((_vm.doctors.data),function(item){return _c('tr',{key:item.doctor_id,staticClass:"table-tr mb-1",staticStyle:{"min-height":"70px"}},[_c('td',{attrs:{"data-label":_vm.$t('workspace_setting.lbl_hd_info')},on:{"click":function($event){return _vm.goToProfile(item && item.doctor_id)}}},[_c('div',{staticClass:"d-flex py-1 align-items-center pl-3"},[_c('div',{staticStyle:{"min-width":"40px"}},[(
                      item &&
                      item.doctor &&
                      item.doctor.user &&
                      item.doctor.user.avatar
                    )?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",staticStyle:{"height":"30px","width":"30px"},style:('background-image: url(' +
                      _vm.getImageURL(item.doctor.user.avatar) +
                      ')')}):_vm._e(),(
                      item &&
                      item.doctor &&
                      item.doctor.user &&
                      !item.doctor.user.avatar
                    )?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white",staticStyle:{"height":"30px","width":"30px"}},[_vm._v(" "+_vm._s(item && item.doctor && item.doctor.name ? item.doctor.name.charAt(0) : "")+" ")]):_vm._e()]),_c('div',{staticClass:"flex-fill"},[(item && item.doctor && !item.doctor.name)?_c('p',{staticClass:"fs-14 fw-500 txt-pri p-0 m-0"},[_vm._v(" "+_vm._s(item && item.doctor && item.doctor.user && item.doctor.user.email ? item.doctor.user.email : "")+" ")]):_c('p',{staticClass:"fs-14 fw-500 txt-pri p-0 m-0"},[_vm._v(" "+_vm._s(item && item.doctor && item.doctor.name ? item.doctor.name : "")+" ")])])])]),_c('td',{attrs:{"data-label":_vm.$t('workspace_setting.lbl_hd_title')}},[(
                  item &&
                  item.doctor &&
                  (item.doctor.type === 0 || item.doctor.type === null)
                )?_c('span',{staticClass:"fs-14",staticStyle:{"color":"#20419b"}},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_doctor")))]):_vm._e(),(item && item.doctor && item.doctor.type === 1)?_c('span',{staticClass:"fs-14",staticStyle:{"color":"#20419b"}},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_health_care_staff")))]):_vm._e(),(item && item.doctor && item.doctor.type === 2)?_c('span',{staticClass:"fs-14",staticStyle:{"color":"#20419b"}},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_specialist_doctor")))]):_vm._e(),(item && item.doctor && item.doctor.type === 3)?_c('span',{staticClass:"fs-14",staticStyle:{"color":"#20419b"}},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_nurse")))]):_vm._e(),(item && item.doctor && item.doctor.type === 4)?_c('span',{staticClass:"fs-14",staticStyle:{"color":"#20419b"}},[_vm._v(_vm._s(_vm.$t("workspace_setting.lbl_hcp")))]):_vm._e()]),_c('td',{attrs:{"data-label":"Liên hệ"}},[(_vm.$user.id !== (item.doctor && item.doctor.user_id))?_c('button',{staticClass:"btn bg-pri text-white mr-2 border-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.messageTo(item.doctor.user_id, $event)}}},[_c('span',[_vm._v("Chat")]),_vm._m(0,true)]):_vm._e()]),_c('td',{attrs:{"data-label":""}},[_c('div',{staticClass:"d-flex justify-content-end"},[(
                    _vm.my_dr_id !== item.doctor_id &&
                    (_vm.isOwnerWorkspace || _vm.isOwnerOrAdmin)
                  )?_c('button',{staticClass:"btn bg--red-corl text--red border-0",on:{"click":function($event){return _vm.deletePersonWorkspace(item && item.doctor_id)}}},[_vm._v(" "+_vm._s(_vm.$t("workspace_setting.btn_delete"))+" ")]):_vm._e()])])])}),0):_vm._e()]),_c('AppPagination',{attrs:{"paginationProps":{
          currentPage: _vm.current_page,
          totalItems: _vm.totalItems,
          pageSize: _vm.limit,
        }},on:{"onChangePagination":(data) => _vm.getDoctorWsp(data)}}),(_vm.doctors && !_vm.doctors.count)?_c('div',[_c('div',{staticClass:"col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden"},[_c('img',{staticClass:"img-responsive p-0",attrs:{"src":require("../../../public/assets/images/not-found-search.png"),"alt":""}}),_c('p',{staticClass:"robo-16-400 text-black"},[_vm._v(" "+_vm._s(_vm.search ? _vm.$t("workspace_setting.lbl_msg_notfound") : _vm.$t("workspace_setting.lbl_msg_no_staff"))+" ")])])]):_vm._e()],1):_vm._e(),(!_vm.can('view-doctor-list') && !_vm.can('view-staff-list'))?_c('div',[_c('p',{staticClass:"mb-0 text-center text-red robo-20-500"},[_vm._v(" "+_vm._s(_vm.$t("workspace_setting.lbl_msg_no_permission"))+" ")])]):_vm._e()]),(_vm.isOwnerWorkspace || _vm.isOwnerOrAdmin)?_c('SignUpStaff',{attrs:{"wsId":_vm.wsId},on:{"refresh":function($event){return _vm.getDoctorWsp()}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border mx-2 loading-btn-icon",attrs:{"role":"status"}},[_c('div',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }